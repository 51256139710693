.ach-intro-text{
    width:80%;
    margin:20px auto;
    font-size: 18px;
    text-align: justify;
    font-weight: bold;
    line-height: 30px;
}
.ach-table{
    width:80%;
    margin:10px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.ach-tr{
display: flex;
justify-content:space-between;
align-items: center;
margin:20px 2px;
width:95%;
}
.ach-td{
    margin-right: 5px;
    text-align: justify;
    width:35%;
}

.video{
    width:46vw;
    height:350px;
}
.table-txt{
    font-size:18px;
    
}
.article{
    font-size: 24px;
}
@media screen and (max-width:800px) {
    .ach-intro-text{
        width:85%;
        margin:20px auto;
        font-size: 16px;
        text-align: justify;
        font-weight: bold;
        line-height: 30px;
    }
    .video{
        width:375px;
        height:225px;
    }
    .table-txt{
        font-size:16px;
    
    }
    .ach-table{
        width:85%;
        margin:10px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
    }
    .ach-tr{
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
        margin:20px auto;
        }
        .ach-td{
            margin: 10px auto;
            width:80%;
        }
        
        .table-txt{
            font-size:16px;
            text-align: center;
            margin:auto;
            
        }
        .article{
            font-size: 16px;
        }
}