.carousel-img-delete {
  /* z-index: 1; */
  width: 70vw;
}

.carousel-img-delete button {
border: none;
color: red;
padding: 5px;
background-color: #302c2c;
}
.loader{
  padding: 200px;
  margin: 250px;
}


.offers-carousel {
  width: 70vw;
}
.carousel-center{
  margin:0 auto;
  padding-top: 20px;
}

@media only screen and (max-width: 600px) {
  .offers-carousel {
    width: 90vw;
  }

}