.topnav {
  overflow: hidden;
  /* background-color: #333; */
  background-color: #364f65;
}

.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 24px;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
}
.logo-text {
  color: #f2f2f2;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
  margin-left: 20px;
  padding-top: 5px;
  font-style: italic;
  margin-top: 5px;
}

/* .active {
  background-color: #04AA6D;
  color: white;
} */

.topnav .icon {
  display: none;
}

.dropdownn {
  float: left;
  overflow: hidden;
}
.dropdownn .dropbtn {
  font-size: 17px;    
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 10;
margin-top: 55px;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  z-index: 10;
  font-weight: normal;
}

.topnav a:hover, .dropdownn:hover .dropbtn {
  background-color: #555;
  color: white;
  z-index: 10;
}

.dropdown-content a:hover {
  background-color: #ddd;
  color: black;
  z-index: 10;
}

.dropdownn:hover .dropdown-content {
  display: block;
}

.logo-img {
  width: 60px;
  height: 100%;
  border-radius: 50%;
  margin-top: 5px;
}
.logo-div{
  padding-right: 10px;
}
.rightside {
  float: right;
  margin-right: 200px;
}

.logout{
  background-color: #333;
  color: white;
  border: none;
}

.fa-user{
  font-size: 24px;
  padding: 12px;

}

.fa-caret-down{
  margin-right: 15px;
}
/* Responsive Styling  */

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  .topnav a {
    font-size: 12px;
    font-weight: bold;
    padding: 10px;
    margin-top: 12px;
  }
  .fa-user{
    font-size: 10px;
    padding: 5px;
  
  }
  .fa-caret-down{
    margin-right: 10px;
  }
  .logo-img{
    width: 35px;
  }
  .logo-text{
    font-size: 8px;
    padding-top: 0;
    margin-top: 0;
  }
  .dropbtn{
    font-size: 8px;
    padding: 3px;
  }
  .rightside {
    float: right;
    margin-right: 10px;
  }
  .logo-div a{
margin: 0;
  }
}

@media screen and (min-width: 600px) {
  .sidebarItems{
  display: none;
  }

}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child),.dropdown .dropbtn  {
    display: none;

  }
  .rightside {display: none;}
  .topnav a.icon {
    float: right;
    display: block;
  }
  .topnav a {
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
  }
  .logo-text {
    color: #f2f2f2;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    font-weight: bold;
    margin-left: 20px;
    padding-top: 5px;
    font-style: italic;
    margin-top: 5px;
  }
  .logo-img {
    width: 60px;
    height: 100%;
    border-radius: 50%;
    margin-top: 5px;
  }
  .logo-div{
    padding-right: 10px;
  }

}

@media screen and (max-width: 600px) {
  .topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .topnav.responsive .dropdown {float: none;}
  .topnav.responsive .dropdown-content {position: relative;}
  .topnav.responsive .dropdown .dropbtn {
    display: block;
    width: 100%;
    text-align: left;
  }

  .fa-bars{
    font-size: 40px;
  }
  .sidebarItems a {
    display: none;
  }
}