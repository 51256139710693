.management-main-container{
    background-image: url("https://treasury-management.com/wp-content/uploads/2021/09/TMI-PIF-background.jpg");
    height:fit-content;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* color: white; */
    font-weight: bold;
    /* object-fit: scale-down; */
    background-size: cover;
}
p{
    font-size: 15px;
    color: rgb(36, 35, 35);
}
.owner-container{
    display: flex;
    justify-content:center;
    align-items: center;
    width:65%;
    margin:30px 0;
    background-color: transparent;
    box-shadow: 2px 2px 5px 5px #676565;
    border-radius: 10px;
    padding: 20px 10px;
    
}

.mng-img-container{
height:220px;
width:220px;
margin-top: 10px;
margin-bottom: 10px;
margin-right: 40px;
background-color: wheat;
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
color: black;
}
.mng-img-container-m{
    height:220px;
    width:220px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 40px;
    background-color: wheat;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    order: 2;
    color: black;
    }
.mng-owner-info{
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
width:60%;
}
.mng-owner-info-m{
   
    order: 1;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
width:60%;
}

.para-icons-container{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width:100%;
    
}
.para{
    margin-right: 25px;
    text-align: justify;
    height:fit-content;
    width:70%;
}

.mng-pg-icon-container{
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}


.individual-icon-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: fit-content;
     }
 .individual-icon{
 margin-right: 5px;
 }


@media screen and (max-width:1050px){
    .management-main-container{
        /* background-image: url("https://i.pinimg.com/236x/09/cf/5d/09cf5d8a406b5bc88f40b442157ae6c9.jpg"); */
        height:fit-content;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:10px;
    }
    .owner-container{
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
        height: fit-content;
        width:80%;
        padding:8px;
        background-color: transparent;
        box-shadow: 2px 2px 5px 5px #676565;
        border-radius: 10px;
        
    }
    .mng-img-container-m{
        height:220px;
        width:220px;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 0;
        background-color: wheat;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        order: 1;
        }
        .mng-img-container{
            height:220px;
            width:220px;
            margin-top: 10px;
            margin-bottom: 10px;
            margin-right: 0;
            background-color: wheat;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            }
        .mng-owner-info-m{
        order:2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width:80%;
        }
        .mng-owner-info{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width:80%;
            }
        .para-icons-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: fit-content;
            width:120%;
        }
        .para{
            text-align: justify;
            height:fit-content;
            width:85%;
        }
        .mng-pg-icon-container{
            height: fit-content;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .individual-icon-container{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: fit-content;
            margin-right:10px;
             }
             .individual-icon{
                margin-right: 3px;
                }
}

@media screen and (min-width:676px) and (max-width:1049px){
    .mng-pg-icon-container{
        height: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .individual-icon-container{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: fit-content;
        margin-right:15px;
         }
}