.categories-div{
  background-color: #c9c7d3;
  display: grid;
  grid-template-columns: 25% 1fr;
}

.sidebar-categories{
  background-color: #5c7183;

}
.gallery-view{


}
.sidebar-items {
  padding: 10px;
  width: 100%;
  background-color: #1f364b;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding:5px 20px; */
}

.delete-btn {
/* color: #c9c8c8; */
font-size: 18px;
background-color: #727176;
border: none;
}
.sidebar-items p{
  color: white;
  font-size: 18px;
}
.sidebar-items:hover {
background-color: #426d94;
}

 /* UPDATE COLLECTIONS STYLINGS  */
.upload-btn {
  background-color: #313B44;
  width: max-content;
  height: max-content;
  color: white;
  font-weight: bold;
  padding: 5px;
}

.preview-Images {
  padding: 20px;
  margin: 0 auto;

}

.input-div {
  background-color: #d3d0d0;
  text-align: center;
  border: 2px dashed black;
}
.dropzone-div {
  padding: 20px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.input-field{
  margin: 10px;
  padding: 10px;
}
.center p {
  padding: 10px;
}

@media only screen and (max-width: 600px) {
  .categories-div{
    background-color: #c9c7d3;
    display: grid;
    grid-template-columns: 30% 1fr;
  }
  .fas {
    color: white;
    font-size: 10px;
  }
  .sidebar-items p{
    font-size: 10px;
  }
.delete-btn {
  width: 30px;
  height: 30px;
}
.dropzone-div h2 ,h3 {
  font-size: 18px;
}
.upload-btn {
width: 100px;
font-size: 10px;
}
}